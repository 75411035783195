import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../../components/header"
import Footer from "../../components/footer"
import "../../styles/style.css"
import Services from "../../components/services/servicios_seguridad"

const Devsecops = () => {
  const data = useStaticQuery(graphql`
    query imagesDevsecops {
      image: allFile(
        filter: { relativeDirectory: { eq: "compania" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      },
      logos: allFile(
        filter: { relativeDirectory: { eq: "offering/logos" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `) 
  
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Empesa especializada en servicos DevSecOps | Qualoom</title>
        <meta name="title" content="Empesa especializada en servicos DevSecOps | Qualoom"/>
        <meta name="description" content="Incorpora Seguridad DevSecOps al proceso de Integración y Desarrollo de DevOps. En Qualoom ofrecemos Servicio de Asesoramiento Gratuito. ¡Infórmate!"/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/seguridad-informatica/devsecops/"/>
        <link rel="canonical" href="https://www.qualoom.es/seguridad-informatica/devsecops/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/seguridad-informatica/devsecops/"/>
        <meta property="og:title" content="Empesa especializada en servicos DevSecOps | Qualoom"/>
        <meta property="og:description" content="Incorpora Seguridad DevSecOps al proceso de Integración y Desarrollo de DevOps. En Qualoom ofrecemos Servicio de Asesoramiento Gratuito. ¡Infórmate!"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3qEkBiWp6XWYzJYweuZVSK/00681335572a0fe04ef29bc8dba9096b/tw_cards_servicios.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/seguridad-informatica/devsecops/"/>
        <meta property="twitter:title" content="Empesa especializada en servicos DevSecOps | Qualoom"/>
        <meta property="twitter:description" content="Incorpora Seguridad DevSecOps al proceso de Integración y Desarrollo de DevOps. En Qualoom ofrecemos Servicio de Asesoramiento Gratuito. ¡Infórmate!"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3qEkBiWp6XWYzJYweuZVSK/00681335572a0fe04ef29bc8dba9096b/tw_cards_servicios.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/seguridad-informatica/devsecops/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Seguridad informática", "item": {"@id": "https://www.qualoom.es/seguridad-informatica/", "name": "Seguridad informática"}},
              {"@type":"ListItem", "position":"3", "name":"DevSecOps, seguridad integrada en DevOps", "item": {"@id": "https://www.qualoom.es/seguridad-informatica/devsecops/", "name": "DevSecOps, seguridad integrada en DevOps"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-servicios">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>DevSecOps, seguridad integrada en DevOps</h1>
              <p>Se trata del proceso continuo de integración y desarrollo de DevOps que incorpora la seguridad en el proceso de producción y puesta a disposición de nuevos productos, conectando los equipos de desarrollo, operaciones y seguridad con el objetivo de garantizar una entrega rápida y segura tanto del código como de todos los componentes resultantes en dicho proceso.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Qué es DevSecOps?</h2><hr></hr>
          <p>Es un método de gestión que combina los equipos de desarrollo, operaciones y seguridad, y la infraestructura como código (IaaS) en un ciclo de entrega continuo y automatizado.</p><br/>
          <h3>¿Para qué sirve DevSecOps?</h3>
          <p>Tiene como objetivo principal implementar la seguridad en todas las fases del ciclo de creación del software para determinar su tolerancia al riesgo y realizar análisis de beneficios de las distintas aplicaciones e infraestructuras.</p>
        </div>
      </div>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>La importancia de DevSecOps</h2><hr></hr>
          <p>La implementación de la seguridad en los procesos de integración y desarrollo continuos permite identificar y responder de una forma más rápida y eficaz a cualquier ataque o posibles.</p><br/>
          <h3>Los beneficios de DevSecOps para tu empresa</h3>
          <p>La incorporación temprana de políticas de seguridad evita la detección de errores críticos al final del proceso que requieren esfuerzo, tiempo y costo adicionales para solucionarlos, o que son inesperados.</p>
          <p>Además, la incorporación de herramientas de seguridad desde las primeras etapas de un proyecto ayuda a los grupos de trabajo a adaptarse al desarrollo de estos enfoques, lo que reduce la posibilidad de que surjan problemas de seguridad.</p>
        </div>
      </div>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Qué servicios ofrece Qualoom?</h2><hr></hr>
          <p>Inmersos en ecosistemas Cloud, OnPremise o Híbridos y rompiendo con los esquemas tradicionales de infraestructura inhouse, la actual administración y gobierno de servicios se realiza casi completamente de manera remota, lo que conlleva tener en cuenta este factor para disponer de unas herramientas de conectividad remota que cumplan con los estándares de seguridad.</p><Services></Services>
        </div>
      </div>
      <Footer />
      </div>
  )
}

export default Devsecops
